import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import api from './Api';
import './App.css';
import logo from './images/logo-placeholder.png';

const Dashboard = () => {
    const [telegramId, setTelegramId] = useState('');
    const [username, setUsername] = useState(''); // New state for username
    const [points, setPoints] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    const handleLogout = () => {
        const confirmLogout = window.confirm('Are you sure you want to log out?');
        if (confirmLogout) {
            logout();
            navigate('/');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        // Payload can include either telegram_id or username
        const payload = {
            telegram_id: telegramId || undefined, // Set to undefined if empty
            username: username || undefined, // Set to undefined if empty
            points: Number(points),
        };

        try {
            const response = await api.put('/user/pray/admin/update-pray-points/', payload, {
                headers: {
                    'Accept': '*/*',
                },
            });

            if (response.status === 200) {
                setMessage('Points updated successfully!');
                setTelegramId('');
                setUsername(''); // Clear username input
                setPoints('');
            } else {
                setMessage('Failed to update points.');
            }
        } catch (error) {
            console.error('Error updating points', error);
            setMessage('This user is not registered');
        }
    };

    return (
        <div className="container">
            <div className='heder-box'>
                <img src={logo} alt='Logo' />
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
            <h2>Dashboard</h2>
            <form onSubmit={handleSubmit}>
                <label>Telegram ID:</label>
                <input
                    type="number"
                    value={telegramId}
                    onChange={(e) => setTelegramId(e.target.value)}
                />
                <p>-------------OR-------------</p>
                <label>Telegram Username:</label>
                <input
                    type="text" // Change to text for usernames
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} // Update username state
                    required={!(telegramId && points)} // At least one field required
                />
                <label>Points:</label>
                <input
                    type="number"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Dashboard;
