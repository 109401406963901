import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './Api'; // Import your API instance
import './App.css';
import logo from './images/logo-placeholder.png'

const Login = () => {
    const [telegramId, setTelegramId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        const payload = {
            telegram_id: telegramId,
            password,
        };

        try {
            const response = await api.post('/user/admin/login/', payload);
            localStorage.setItem('accessToken', response.data.access); // Store token
            navigate('/dashboard'); // Redirect to dashboard
        } catch (error) {
            console.error('Login error:', error);
            setError('Invalid credentials or user not an admin.'); // Set error message
        }
    };

    return (
        <div className="container">
            <div className='heder-box'>
                <img src={logo} alt='' />
                <h2>Admin Login</h2>
            </div>
            <form onSubmit={handleLogin}>
                <label>Admin ID:</label>
                <input
                    type="text"
                    value={telegramId}
                    onChange={(e) => setTelegramId(e.target.value)}
                    required
                />
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
            {error && <p className="error">{error}</p>} {/* Display error message */}
        </div>
    );
};

export default Login;
