import axios from 'axios';

const api = axios.create({
  baseURL: 'https://appbe.masterverses.com/api/', // Set your API base URL
});

// Intercept requests to include the access token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken'); // Get the token from local storage
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Attach the token
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Optionally handle response errors globally
api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    // Token might be expired, implement refresh logic if necessary
  }
  return Promise.reject(error);
});

export default api;
